<template>
    <div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="filter-container">
            <div class="left-content"/>
            <div class="right-content">
                <div class="filter-actions">
                    <b-button variant="default icon-left" @click="closeAddEdit"><i class="icon icon-keyboard icon-rotate-180"></i>
                        Назад к списку
                    </b-button>
                    <b-button variant="success" @click="save()">Сохранить</b-button>
                </div>
            </div>
        </div>
        <div class="form-card initial-form-passport">
            <div class="passport-item h-60">
                <div class="title">
                    <span>Наименование</span>
                </div>
                <div class="content once">
                    <b-form-input type="text" style="text-transform: uppercase; font-weight: bold; color: rgb(5 48 83); font-size: unset;" v-model="programPassportName" disabled></b-form-input>
                </div>
            </div>
            <div class="passport-item">
                <div class="title">
                    <span>Основание для разработки</span>
                </div>
                <div class="content table-content">
                        <div class="table-style" :key="`npaIndex_${npaIndex}`"  v-for="(npaElement, npaIndex) of dictNpaArr">
                            <div class="w-50">{{ npaIndex+1 }}</div>
                            <div class="full">
                                <multiselect
                                    v-model="npaElement.npa"
                                    track-by="id"
                                    label="name"
                                    :value='value'
                                    :disabled="npaElement.disabled"
                                    placeholder="Выбрать ..."
                                    :options="dictNpaList"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    :multiple="false"
                                    @input="chgNpa(npaElement, npaIndex)"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span style="text-transform: none;" v-if="option.name.length<150">{{ option.name }}</span>
                                            <span style="text-transform: none;" v-else :title="option.nameRu">{{option.name.slice(0, 150) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </div>
                            <div class="w-50" v-if="!isProgramPassportExpired">
                                <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                    <template v-slot:button-content>
                                        <i class="icon icon-more"></i>
                                    </template>
                                    <template>
                                        <b-dropdown-item @click="addRowForMeaningOfDev()">
                                            Добавить
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="editRowForMeaningOfDev(npaElement, npaIndex)">
                                            Редактировать
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteMeaningRow(npaElement, npaIndex)">
                                            Удалить
                                        </b-dropdown-item>
                                    </template>
                                </b-dropdown>
                            </div>
                        </div>
                    <template v-if="!isProgramPassportExpired">
                        <b-link @click="addRowForMeaningOfDev()" class="add-link"> <i class="icon icon-plus-circle add-button"></i> <span>Добавить</span></b-link>
                    </template>
                </div>
            </div>
            <div class="passport-item">
                <div class="title">
                    <span>Сроки реализации</span>
                </div>
                <div class="content once">
                    <b-form-input type="text" v-model="programPassportDates" disabled></b-form-input>
                </div>
            </div>
            <div class="passport-item">
                <div class="title">
                    <span>Цели</span>
                </div>
                <div class="content table-content">
                        <div class="table-style" :key="`goalsIndex_${goalsIndex}`" v-for="(goalsElement, goalsIndex) of dictGoalsArr">
                            <div class="w-50">{{ goalsIndex+1 }}</div>
                            <div class="full">
                                <multiselect
                                    v-model="goalsElement.goals"
                                    track-by="id"
                                    label="name"
                                    :value='goalval'
                                    :disabled="goalsElement.disabled"
                                    placeholder="Выбрать ..."
                                    :options="dictProgramPassportGoals"
                                    :searchable="true"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    :multiple="false"
                                    @input="chgPrgGoals(goalsElement, goalsIndex)"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="{ option }"
                                    >
                                        <template v-if="option.name!==undefined">
                                            <span style="text-transform: none;" v-if="option.name.length<150">{{ option.name }}</span>
                                            <span style="text-transform: none;" v-else :title="option.name_ru">{{option.name.slice(0, 150) + '...'}}</span>
                                        </template>
                                    </template>
                                </multiselect>
                            </div>
                            <div class="w-50" v-if="!isProgramPassportExpired">
                                <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                    <template v-slot:button-content>
                                        <i class="icon icon-more"></i>
                                    </template>
                                    <template>
                                        <b-dropdown-item @click="addRowForGoalsOfDev()">
                                            Добавить
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="editRowForGoalsOfDev(goalsElement, goalsIndex)">
                                            Редактировать
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteGoalsRow(goalsElement, goalsIndex)">
                                            Удалить
                                        </b-dropdown-item>
                                    </template>
                                </b-dropdown>
                            </div>
                        </div>
                    <template v-if="!isProgramPassportExpired">
                        <b-link @click="addRowForGoalsOfDev()" class="add-link"><i class="icon icon-plus-circle add-button"></i> <span>Добавить</span></b-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/services/store";
import axios from "axios";
import Multiselect from 'vue-multiselect'
import VueElementLoading from 'vue-element-loading';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: "PassportOfProgram",
    components: {
        'multiselect': Multiselect,
        'loading': VueElementLoading
    },
    mounted() {
        this.getObl();
        this.showDictProgramGoalsList();
        this.$watch('currentProgramPassport.programPassportId', this.setParams);
    },
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        goalval: {
            type: String,
            required: false,
            default: ''
        },
        currentProgramPassport: {
            required: true,
            default: null
        }
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            currentDay: new Date().getDay(),
            dictNpaList: [],
            obl: '',
            region: '',
            loading: false,
            dictProgramPassportGoals: [],
            listOfGoalsInOrNotInForecast: [],
            dictProgramPassportID: null,
            dictNpaSelectedList: {
                data: []
            },
            dictGoalSelected: {
                data: []
            },
            programId: null,
            initialArrNpa: new Map(),
            initialArrGoal: new Map()
        }
    },
    methods: {
        usrId() {
            if (store.state.user.sub === undefined) { return null; }
            return store.state.user.sub;
        },

        setParams() {
            this.loading = true;
            const currentProgramPassport = this.currentProgramPassport;
            // console.log('this.currentProgramPassport: ' + JSON.stringify(this.currentProgramPassport));
            if (currentProgramPassport === null){
                this.programPassportName = null;
                this.programPassportDates = null;
                this.dictNpaSelectedList.data = [];
                this.dictGoalSelected.data = [];
            } else {
                this.programId = this.currentProgramPassport.programId;
                this.dictProgramPassportID = currentProgramPassport.programPassportId;
                this.getPrgPassportNpaAndGoals(this.dictProgramPassportID);
            }
        },

        // -------Метод для извлечения области и региона-------//
        async getObl() {    // -----Извлекается регион и область из глобальных настроек пользователя
            let region = null;
            let oblast = null;
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        oblast = json.obl;
                        // this.region = json.region;
                        region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
            // const usr = store.getters.user_uuid;
            const usr = this.userId;
            // console.log('usr: ' + usr);

            // let region = this.region;
            try {
                await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + usr)
                    .then(response => response.json())
                    .then(json => {
                        if (json.length && json[0].code) { region = json[0].code; }
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-user-budget-region', error.toString());
            }
            this.region = region;
            console.log('this.obl: ' + this.obl + ' region: ' + region + ' this.usrId: ' + JSON.stringify(usr));
            await this.showDictNpaList(oblast, region);
        },

        padLeadingZeros(num, size) {    // -------добавление нулей после значения в зависимости от размера значения
            let s = num;
            while (s.length < size) { s = s + '0'; }
            return s;
        },

        //-----Извлечь ранее сохраненные записи в БД по ProgramPassportId
        async getPrgPassportNpaAndGoals(prgPassportId){
            if (prgPassportId && prgPassportId!=="") {
                let listOfPrgPassportNpa = [];
                let listOfPrgPassportGoals = [];
                this.dictNpaSelectedList.data = [];
                this.dictGoalSelected.data = [];
                const listOfGoalsForChecking = [];
                try {
                    listOfPrgPassportNpa = fetch('/api/program-passport/get-program-passport-npa/' + prgPassportId);
                    listOfPrgPassportGoals = fetch('/api/program-passport/get-program-passport-goal/' + prgPassportId);
                    Promise.all([listOfPrgPassportNpa, listOfPrgPassportGoals]) // ---метод позволяющий одновременно обращаться через ajax по двум ссылкам
                        .then(values => {
                            return Promise.all(values.map(resp => resp.json()));
                        }).then(async ([listNpa, listGoals]) => {
                            if (listNpa.length > 0) {
                                for (const el of listNpa) {
                                    const npaObj = {
                                        id: el.dictNpa.id,
                                        program_passport_id: this.dictProgramPassportID,
                                        dict_npa_id: el.dictNpa.id,
                                        npa: el.dictNpa,
                                        disabled: true
                                    }
                                    this.dictNpaSelectedList.data.push(this.setNameLangs(npaObj));
                                    this.initialArrNpa.set(npaObj.id, JSON.parse(JSON.stringify(npaObj)));
                                }
                            }

                            if (listGoals.length > 0){
                                for (const el of listGoals) {
                                    const goalsObj = {
                                        id: el.dictProgramGoal.id,
                                        program_passport_id: this.dictProgramPassportID,
                                        dict_program_goal_id: el.dictProgramGoal.id,
                                        goals: el.dictProgramGoal,
                                        disabled: true,
                                        deletable: false,
                                        editable: false
                                    }
                                    if (el.dictProgramGoal.id){
                                        listOfGoalsForChecking.push(el.dictProgramGoal.id);
                                    }
                                    this.dictGoalSelected.data.push(this.setNameLangs(goalsObj, 'goals'));
                                    this.initialArrGoal.set(goalsObj.id, JSON.parse(JSON.stringify(goalsObj)));
                                }
                                await this.getListOfGoalsInForecast(listOfGoalsForChecking);
                            }
                        this.loading = false;
                    });

                } catch (error) {
                    this.makeToast('danger', 'Ошибка загрузки ProgramPassportNPA ', error.toString());
                }
            }
        },

        async getListOfGoalsInForecast(arr){
            // console.log('programId: ' + this.programId);
            try {
                if (arr.length > 0) {
                    const headers = {
                        'Content-Type': 'application/json'
                    };
                    const result = await axios.post(`/api/program-passport/list-of-goals-in-forecast/${this.programId}`, arr, {headers});
                    if (result.status === 200) {
                        // console.log('Positive_result: ' + JSON.stringify(result.data));
                        if (result.data.length > 0 && this.dictGoalSelected.data.length > 0) {
                            for (const goalsInForecast of result.data) {
                                for (const goals of this.dictGoalsArr) {
                                    if (goalsInForecast.dict_program_goals.id === goals.id){
                                        goals.deletable = goalsInForecast.inForecast;
                                        goals.editable = goalsInForecast.inForecast;
                                    }
                                }
                                for (const goals of this.dictGoalSelected.data) {
                                    if (goalsInForecast.dict_program_goals.id === goals.id){
                                        goals.deletable = goalsInForecast.inForecast;
                                        goals.editable = goalsInForecast.inForecast;
                                    }
                                }
                            }
                            // console.log('this.dictGoalsArr: ' + JSON.stringify(this.dictGoalsArr));
                        }
                    } else {
                        this.makeToast('danger', 'Сравнение значений цели с таблицей forecast на наличие связи', `Ошибка ${result.status} ${result.statusText}`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        //----Добавление новой записи в "Основание для разработки"
        addRowForMeaningOfDev(){
            const npaObj = {
                id: '',
                program_passport_id: '',
                dict_npa_id: '',
                npa: {},
                disabled: false
            }
            this.dictNpaSelectedList.data.push(npaObj);
        },

        //----Добавленеи новой записи в Цели
        addRowForGoalsOfDev(){
            const goalsObj = {
                id: '',
                program_passport_id: '',
                dict_program_goal_id: '',
                goals: {},
                disabled: false
            }
            this.dictGoalSelected.data.push(goalsObj);
        },

        //-----Изменение текущей выборки в "Основание для разработки" <multiselect>
        chgNpa(value, npaIndex){
            if (value !== undefined) {
                const npaObj = {
                    id: value.npa.id,
                    program_passport_id: this.dictProgramPassportID,
                    dict_npa_id: value.npa.id,
                    npa: value.npa,
                    disabled: false
                }

                //----Если "основания для разработки" пустые
                if (this.dictNpaSelectedList.data.length === 0) {
                    this.dictNpaSelectedList.data.push(npaObj);
                } else {
                    //----Проверка на дубликаты при добавлении
                    const isDuplicate = this.checkDuplicates(npaObj, this.dictNpaSelectedList.data);
                    if (isDuplicate){
                        // console.log('isDuplicate: ' + isDuplicate);
                        if (this.dictNpaSelectedList.data[npaIndex].id === ""){
                            //----Если был добавлен новый пустой multiselect и в нём был выбрано повторяющееся значение в другом multiselect
                            //----В этом случае данный multiselect удаляется вместе с содержимым
                            this.dictNpaSelectedList.data = this.removeRowByIndex(this.dictNpaSelectedList.data, npaIndex);
                        } else {
                            //----Если выборка ранее содержала выбранное значение, то в случае дубля этот выбор не будет изменен
                            value.npa = this.getObject(this.dictNpaSelectedList.data, this.dictNpaSelectedList.data[npaIndex]);
                        }
                        return this.makeToast('danger', 'Дубликат', "Данная запись уже присутствует");
                    } else {
                        //----Если "основание для разработки" с данным индексом уже имеет значение, то меняем значение
                        if (this.dictNpaSelectedList.data[npaIndex] !== undefined && this.dictNpaSelectedList.data[npaIndex] !== null) {
                            this.dictNpaSelectedList.data[npaIndex].id = npaObj.dict_npa_id;
                            this.dictNpaSelectedList.data[npaIndex].program_passport_id = npaObj.program_passport_id
                            this.dictNpaSelectedList.data[npaIndex].dict_npa_id = npaObj.dict_npa_id;
                            this.dictNpaSelectedList.data[npaIndex].npa = npaObj.npa;
                        } else {
                            //----Добавляется новое значение "основания для разработки"
                            this.dictNpaSelectedList.data[npaIndex] = npaObj;
                        }
                    }
                }
            }
        },

        //----Проверка дублей
        checkDuplicates(obj, arr){
            if (arr.length > 0){
                for (const el of arr){
                    if (el.id === obj.id) {
                        return true;
                    }
                }
            }
            return false;
        },

        //----Извлекает объект из массива по id
        getObject(arr, obj){
            if (arr.length > 0){
                for (const el of arr){
                    if (el.id === obj.id) {
                        return el;
                    }
                }
            }
            return obj;
        },

        //-----Изменение текущей выборки в "Целях" <multiselect>
        chgPrgGoals(goalVal, goalsIndex){
            if (goalVal !== undefined) {
                const goalsObj = {
                    id: goalVal.goals.id,
                    program_passport_id: this.dictProgramPassportID,
                    dict_program_goal_id: goalVal.goals.id,
                    goals: goalVal.goals,
                    disabled: false
                }

                //----Если "Цели" пустые
                if (this.dictGoalSelected.data.length === 0) {
                    this.dictGoalSelected.data.push(goalsObj);
                } else {
                    //----Проверка на дубликаты при добавлении
                    const isDuplicate = this.checkDuplicates(goalsObj, this.dictGoalSelected.data);
                    if (isDuplicate){
                        if (this.dictGoalSelected.data[goalsIndex].id === ""){
                            //----Если был добавлен новый пустой multiselect и в нём был выбрано повторяющееся значение в другом multiselect
                            //----В этом случае данный multiselect удаляется вместе с содержимым
                            this.dictGoalSelected.data = this.removeRowByIndex(this.dictGoalSelected.data, goalsIndex);
                        } else {
                            //----Если выборка ранее содержала выбранное значение, то в случае дубля этот выбор не будет изменен
                            goalVal.goals = this.getObject(this.dictGoalSelected.data, this.dictGoalSelected.data[goalsIndex]);
                        }
                        return this.makeToast('danger', 'Дубликат', "Данная запись уже присутствует");
                    } else {
                        //----Если "Цели" с данным индексом уже имеет значение, то меняем значение
                        if (this.dictGoalSelected.data[goalsIndex] !== undefined && this.dictGoalSelected.data[goalsIndex] !== null) {
                            this.dictGoalSelected.data[goalsIndex].id = goalsObj.dict_program_goal_id;
                            this.dictGoalSelected.data[goalsIndex].program_passport_id = goalsObj.program_passport_id
                            this.dictGoalSelected.data[goalsIndex].dict_npa_id = goalsObj.dict_program_goal_id;
                            this.dictGoalSelected.data[goalsIndex].goals = goalsObj.goals;
                        } else {
                            //----Добавляется новое значение "Цели"
                            this.dictGoalSelected.data[goalsIndex] = goalsObj;
                        }
                    }
                }
            }
        },

        //-----Удаляет элемент массива по индексу
        removeRowByIndex(arr, indx){
            const tmpArr = [];
            if (arr.length>0){
                for (let i = 0; i < arr.length; i++) {
                    if (i !== indx){
                        tmpArr.push(arr[i]);
                    }
                }
                arr = tmpArr;
            }
            return arr;
        },

        editRowForMeaningOfDev(value, indx){
            this.dictNpaSelectedList.data[indx].disabled = false;
        },

        editRowForGoalsOfDev(el, indx){
            if (el.editable !== true) {
                this.dictGoalSelected.data[indx].disabled = false;
            } else {
                this.makeToast('danger', 'Внимание', 'Редактирование цели программы невозможно при наличии по ней целевых индикаторов');
            }
        },

        deleteMeaningRow(el, indx){
            this.dictNpaSelectedList.data = this.removeRowByIndex(this.dictNpaSelectedList.data, indx);
        },

        deleteGoalsRow(el, indx){
            // console.log('el: ' + JSON.stringify(el));
            if (el.deletable !== true){
                this.dictGoalSelected.data = this.removeRowByIndex(this.dictGoalSelected.data, indx);
            } else {
                this.makeToast('danger', 'Внимание', 'Удаление цели программы невозможно при наличии по ней целевых индикаторов');
            }
        },

        closeAddEdit(){
            this.edit = false;
            this.$emit('closePassport', this.edit);
        },

        //------Сравнение двух массивов
        compareTwoArrays(array1, array2){
            let result = false;
            const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);

            if (array1.size > 0 || array2.length > 0) {
                if (array1.size !== array2.length){
                    return true;
                }
                for (const element of array2){
                    if (element !== undefined) {
                        const initialObj = array1.get(element.id) !== undefined ? array1.get(element.id) : {};
                        if (!equals(element, initialObj)){
                            result = true;
                        }
                    }
                }
            }
            return result;
        },

        async showDictNpaList(oblast, region) {
            const dateObj = new Date();
            const month = dateObj.getUTCMonth() + 1; //months from 1-12
            const day = dateObj.getUTCDate();
            const year = dateObj.getUTCFullYear();
            let mainReg = '';
            if (region.endsWith('0101')){
                mainReg = oblast;
            } else {
                mainReg = oblast+'0101';
            }
            // console.log('mainReg: ' + mainReg);

            let response = [];
            const result = [];
            // console.log('this.region: ' + region);
            try {
                response = await fetch('/api/dict/npa_list?year=' + year+'&month=' + month + '&day='+ day + '&region=' + region + '&oblast=' + mainReg);
                response = await response.json();
                if (response.length > 0) {
                    for (const el of response){
                        result.push(this.setNameLangs(el, 'npa'));
                    }
                }
                this.dictNpaList = result;
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки DictNPA', error.toString());
            }
        },

        async showDictProgramGoalsList(){
            let response = [];
            try {
                response = await fetch('/api/dict/get-goals');
                response = await response.json();
                if (response.length > 0){
                    for (const el of response) {
                        this.dictProgramPassportGoals.push(this.setNameLangs(el, 'goals'));
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки DictProgramGoals', error.toString());
            }
        },

        async save() {
            const npaLists = this.compareTwoArrays(this.initialArrNpa, this.dictNpaSelectedList.data);
            const goalsLists = this.compareTwoArrays(this.initialArrGoal, this.dictGoalSelected.data);
            let npaIsNull = false;
            let goalIsNull = false;
            if (this.dictNpaSelectedList.data.length > 0 && npaLists) {
                const npaArr = [];
                for (const npa of this.dictNpaSelectedList.data) {
                    if (Object.keys(npa.npa).length === 0){
                        npaIsNull = true;
                    }
                    npa.disabled = true;
                    npaArr.push(npa.id);
                }
                if (!npaIsNull){
                    await this.saveNpaDataToDB(npaArr);
                } else {
                    this.makeToast('danger', 'Сохранение оснований для разработки', 'Были созданы и не заполнены поля ввода. Необходимо заполнить пустые поля либо удалить');
                }
            }

            if (this.dictGoalSelected.data.length > 0 && goalsLists) {
                const goalsArr = [];
                for (const goal of this.dictGoalSelected.data){
                    if (Object.keys(goal.goals).length === 0){
                        goalIsNull = true;
                    }
                    goal.disabled = true;
                    goalsArr.push(goal.dict_program_goal_id);
                }
                if (!goalIsNull){
                    await this.saveGoalDataToDB(goalsArr);
                } else {
                    this.makeToast('danger', 'Сохранение Цели', 'Были созданы и не заполнены поля ввода. Необходимо заполнить пустые поля либо удалить');
                }
            }
        },

        //-----Сохранить данные в БД (program_passport_npa)
        async saveNpaDataToDB(arr){
            try {
                if (arr.length > 0) {
                    const headers = {
                        'Content-Type': 'application/json'
                    };
                    const result = await axios.post(`/api/program-passport/add-program-passport-npa/${this.dictProgramPassportID}`, arr, {headers});
                    if (result.status === 200 && result.data === true) {
                        // console.log('result: ' + result.data);
                        this.makeToast('success', 'Сохранение оснований для разработки', 'Данные сохранены!');
                    } else {
                        this.makeToast('danger', 'Сохранение оснований для разработки', `Ошибка ${result.status} ${result.statusText}`);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        //-----Сохранить данные в БД (program_passport_goal)
        async saveGoalDataToDB(arr) {
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const result = await axios.post(`/api/program-passport/add-program-passport-goal/${this.dictProgramPassportID}`, arr, {headers});
                if (result.status === 200 && result.data === true) {
                    this.makeToast('success', 'Сохранение целей', 'Данные сохранены!');
                } else {
                    this.makeToast('danger', 'Сохранение целей', `Ошибка ${result.status} ${result.statusText}`);
                }
            } catch (error) {
                console.log(error);
            }
        },

        // ----Создание объектов на основе переданных данных из справочников----//
        setNameLangs(obj, codeName) {
            // let txt = obj['name_' + this.$i18n.locale];
            let txt = '';
            if (codeName === 'npa') {
                txt = obj.nameRu;
            } else {
                txt = obj.name_ru;
            }
            if (txt !== undefined) {
                if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                    txt = obj[codeName] + ' - ' + txt;
                    // console.log('txt: ' + txt);
                }
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        // ------Метод для отображения сообщения с ошибками----//
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: true
            });
        } // сообщение с ошибкой

    },
    computed: {
        //------Замена массива для отображения в "<multiselect>" ранее сохраненных значений в БД
        optionForValue() {
            const map = Object.create(null)
            for (const option of this.dictNpaList) {
                map[option.id] = option
            }
            return map
        },
        dictNpaArr() {
            const dictNpaList = this.optionForValue
            return this.dictNpaSelectedList.data.map(npaElement => {
                return {
                    ...npaElement,
                    get npa() {
                        return dictNpaList[npaElement.dict_npa_id];
                    },
                    set npa(option) {
                        npaElement.dict_npa_id = option.id;
                    }
                }
            })
        },
        optionGoalForValue() {
            const map = Object.create(null)
            for (const option of this.dictProgramPassportGoals) {
                map[option.id] = option
            }
            return map
        },
        dictGoalsArr() {
            const dictProgramPassportGoals = this.optionGoalForValue
            return this.dictGoalSelected.data.map(goalElement => {
                return {
                    ...goalElement,
                    get goals() {
                        return dictProgramPassportGoals[goalElement.dict_program_goal_id];
                    },
                    set goals(option) {
                        goalElement.dict_program_goal_id = option.id;
                    }
                }
            })
        },
        programPassportName(){
            return this.currentProgramPassport.nameRu;
        },
        programPassportDates(){
            const startDate = new Date(this.currentProgramPassport.startDate).getFullYear();
            const endDate = new Date(this.currentProgramPassport.endDate).getFullYear();
            return startDate + ' - ' + endDate;
        },
        isProgramPassportExpired(){
          return this.currentProgramPassport.expired;
        },
        userId() {
            return this.$store.getters.user_uuid;
        }
    }

}
</script>

<style scoped>
</style>
